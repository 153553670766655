import React from 'react'
import {Link} from 'react-router-dom'
import {Form, Formik} from 'formik'

import {mapKeys, snakeCase} from 'lodash'

import {connect} from 'react-redux'
import {resetPassword} from 'avoapp-react-common/dist/redux/auth'

import {authSchema} from '../../assets/validations'
import logo from '../../assets/images/logo-advocatus.png'

import {Input} from '../../components/Input'
import {Button} from '../../components/Button'
import {ErrorsList} from '../../components/ErrorComponents'

import './ResetPassword.scss'

export const ResetPassword = ({
    fieldErrors,
    nonFieldErrors,
    isLoading,
    resetPassword,
    match: {params: {resetPasswordUID, resetPasswordCode}}
}) => {

    const preparedFieldErrors = mapKeys(fieldErrors, (value, key) => snakeCase(key))

    return (
        <div className="reset-password-container">
            <div className="main-container">
                <div className="header">
                    <img className="logo" src={logo} alt="AvoApp"/>
                    <h1 className="title">Resetare parolă</h1>
                </div>
                <div className="m-6 mt-0">
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{
                            new_password1: '',
                            new_password2: '',
                            uid: resetPasswordUID,
                            code: resetPasswordCode
                        }}
                        validationSchema={authSchema.resetPassword}
                        onSubmit={(values) =>
                            resetPassword({
                                new_password1: values.new_password1,
                                new_password2: values.new_password2,
                                uid: resetPasswordUID,
                                code: resetPasswordCode
                            })
                        }
                    >
                        {({handleChange, handleBlur, values, handleSubmit, errors, touched, isValid}) => (
                            <Form className="space-y-6">
                                <Input
                                    label='Parola'
                                    value={values.new_password1}
                                    onChange={handleChange('new_password1')}
                                    onBlur={handleBlur('new_password1')}
                                    name='new_password1'
                                    errors={preparedFieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.new_password1}
                                    autoComplete='password'
                                    type='password'
                                    fullWidth
                                />
                                <Input
                                    label='Confirmare parola'
                                    value={values.new_password2}
                                    onChange={handleChange('new_password2')}
                                    autoComplete='password'
                                    type='password'
                                    name='new_password2'
                                    errors={preparedFieldErrors}
                                    fullWidth
                                />
                                <Button
                                    title='Schimbă parola'
                                    onClick={handleSubmit}
                                    disabled={!isValid}
                                    loading={isLoading}
                                    fullWidth
                                />
                            </Form>
                        )}
                    </Formik>
                    <div className="back-to-login-container">
                        <Link to='/login' className='back-to-login-link'>
                            Înapoi la login
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    fieldErrors: state.auth.fieldErrors,
    nonFieldErrors: state.auth.nonFieldErrors,
    isLoading: state.auth.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    resetPassword: (values) => dispatch(resetPassword(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
