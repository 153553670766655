const colors = {
    primary: '#151392',
    primaryDark: '#080738',
    primaryLight: '#221fdc',
    avoGreen: '#13D1C6',
    avoGreenLight: '#28fff1',
    avoGreenDark:'#0a7974',
    avoRed: '#ff544c',
    avoRedDark: '#a50700',
    avoRedLight: '#ffa9a5',
    avoOrange: '#f0833a',
    avoOrangeLight: '#f4a875',
    avoOrangeDark: '#8a3d0a'
}

module.exports = colors